.button {
  width: fit-content;
  margin-top: 6px;

  border: none;
  border-radius: 5px;
  background-color: rgb(3, 128, 128);

  font-size: 20px;
  font-weight: 700;
  color: #fff;

  padding: 5px;
}
.button:hover {
  cursor: pointer;
  background-color: aqua;
}



.aboutSubheading{
  color:blue;
  font-size: 18px;
}

.aboutContainer{
  width: 90%;
  background-color: aliceblue;
  border-radius: 8px;
  border: 1px solid aqua;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:8px
}